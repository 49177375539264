<template>
    <div class="h-100">
        <div class="filter-container">
            <div class="left-content">
                <b-dropdown ref="drop"
                            class="filter-dropdown"
                            variant="default">
                    <template #button-content>
                        <span class="lc"><i class="icon icon-filter"></i>{{ getBipTitle('filter.title') }}</span>
                        <span class="rc"><i class="icon icon-keyboard"></i></span>
                    </template>
                    <div>
                        <div class="top-content">
                            <span>{{ getFilterTitle('title') }}</span>
                            <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                        </div>
                        <!--------------- Плановый период ------------------->
                        <div class="filter-block">
                            <b-form-group :label="getFilterTitle('plan_period')">
                                <multiselect ref="bcPlanYear"
                                             v-model="planPeriod"
                                             track-by="name"
                                             label="name"
                                             :options="yearsFilter"
                                             :searchable="false"
                                             :allow-empty="false"
                                             :show-labels="false"
                                             @input="chgData">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Область/район ------------------->
                        <div class="filter-block">
                            <b-form-group :label="getFilterTitle('region_budget')">
                                <multiselect ref="bcRegionBudget"
                                             v-model="selRegionBudget"
                                             track-by="text"
                                             label="text"
                                             :options="regionBudgetList"
                                             :searchable="true"
                                             :allow-empty="false"
                                             :show-labels="false"
                                             @input="chgData">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Вид данных ------------------->
                        <div class="filter-block">
                            <b-form-group :label="getFilterTitle('data_type')">
                                <multiselect ref="bcDataType"
                                             v-model="selDataType"
                                             track-by="name"
                                             label="name"
                                             :options="dataTypeFilter"
                                             :searchable="true"
                                             :allow-empty="false"
                                             :show-labels="false"
                                             @input="changeFields">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Версия бюджета ------------------->
                        <div class="filter-block">
                            <b-form-group :label="getFilterTitle('version')">
                                <multiselect ref="bcVersion"
                                             v-model="selVersion"
                                             track-by="text"
                                             label="text"
                                             :options="versionFilter"
                                             :searchable="true"
                                             :allow-empty="false"
                                             :show-labels="false"
                                             @input="chgData">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- АБП ------------------->
                        <div class="filter-block">
                            <b-form-group :label="getFilterTitle('ABP')">
                                <b-form-select ref="bcAbp"
                                               v-model="selAbp"
                                               :options="dictTree"
                                               value-field="value"
                                               label-field="text"
                                               :placeholder="getFilterTitle('all')"
                                               @input="addAbp">
                                    <template #first>
                                        <b-form-select-option :value="null">
                                            {{ getFilterTitle('sel_abp') }}
                                        </b-form-select-option>
                                    </template>
                                </b-form-select>
                            </b-form-group>
                        </div>
                    </div>
                </b-dropdown>
            </div>
            <div class="right-content" v-if="editing">
                <div class="filter-actions">
                    <b-button variant="success"
                              v-if="[3, 4].includes(selVersion.data_type)"
                              @click="openCorrect">
                        {{ getCdfTitle('reasons') }}
                    </b-button>
                    <b-button variant="success"
                              v-if="!firstVariant"
                              @click="saveDatas">
                        {{ getBipTitle('btn.save') }}
                    </b-button>
                    <b-button variant="success"
                              v-if="[2, 3].includes(userLevel) && operationCode.includes('uebp_agree')"
                              @click="agreementEvent">
                        {{ getBipTitle('btn.actions') }}
                    </b-button>
                </div>
            </div>
        </div>
        <!--   хлебные крошки   -->
        <div class="filter-breadcrumb">
            <span v-if="!openFilterWindow && planPeriod"
                  class="item-breadcrumb"
                  @click="openFilterByRef('bcPlanYear')">
                {{ planPeriod.name }}
            </span>
            <span v-if="!openFilterWindow && selRegionBudget"
                  class="item-breadcrumb"
                  @click="openFilterByRef('bcRegionBudget')">
                {{ selRegionBudget.name }}
            </span>
            <span v-if="!openFilterWindow && selDataType"
                  class="item-breadcrumb"
                  @click="openFilterByRef('bcDataType')">
                {{ selDataType.name }}
            </span>
            <span v-if="!openFilterWindow && selVersion"
                  class="item-breadcrumb"
                  @click="openFilterByRef('bcVersion')">
                {{ selVersion.name }}
            </span>
            <span v-if="!openFilterWindow && selAbp"
                  class="item-breadcrumb"
                  @click="openFilterByRef('bcAbp')">
                {{ selAbp.abp + '-' + getFilterTitle('ABP') }}
            </span>
        </div>
        <!--   таблица   -->
        <div class="table-container">
            <b-table v-if="selVersion !== null"
                     :fields="tableFields"
                     :items="budgetTable"
                     :tbody-tr-class="rowClass"
                     :filter="filter.search"
                     :filter-included-fields="filter.on"
                     responsive="true"
                     bordered
                     head-variant="light"
                     sticky-header="true"
                     no-border-collapse>
                <template #thead-top="data">
                    <b-tr>
                        <b-th colspan="9"></b-th>
                        <b-th colspan="2" class="text-center">
                            {{ planPeriod.year + getCardTitle('sector_1', 'year').toLowerCase() }}
                        </b-th>
                        <b-th colspan="2" class="text-center">
                            {{ parseInt(planPeriod.year) + 1 + getCardTitle('sector_1', 'year').toLowerCase() }}
                        </b-th>
                        <b-th colspan="2" class="text-center">
                            {{ parseInt(planPeriod.year) + 2 + getCardTitle('sector_1', 'year').toLowerCase() }}
                        </b-th>
                        <b-th colspan="3"></b-th>
                    </b-tr>
                </template>

                <template #head(check)="scope">
                    <b-form-checkbox v-model="check"
                                     v-if="selVersion && !selVersion.status && selVersion.attribute &&
                                           [2, 3].includes(userLevel) && operationCode.includes('uebp_agree')">
                    </b-form-checkbox>
                </template>
                <template #head(action)="scope">
                    <b-button @click="openAll()">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="open"></i>
                        <i class="icon icon-chevron-circle" v-if="!open"></i>
                    </b-button>
                </template>
                <template #head(abp)="scope">
                    <div>{{ getFilterTitle('ABP') }}</div>
                    <b-form-input
                        id="filter-input"
                        v-model="filter.abp"
                        type="search"
                        class="mini"
                    ></b-form-input>
                </template>
                <template #head(prg)="scope">
                    <div>{{ getTFieldTitle('prg') }}</div>
                    <b-form-input
                        id="filter-input"
                        v-model="filter.prg"
                        type="search"
                        class="mini"
                    ></b-form-input>
                </template>
                <template #head(ppr)="scope">
                    <div>{{ getTFieldTitle('ppr') }}</div>
                    <b-form-input
                        id="filter-input"
                        v-model="filter.ppr"
                        type="search"
                        class="mini"
                    ></b-form-input>
                </template>
                <template #head(spf)="scope">
                    <div>{{ getTFieldTitle('spf') }}</div>
                    <b-form-input
                        id="filter-input"
                        v-model="filter.spf"
                        type="search"
                        class="mini"
                    ></b-form-input>
                </template>
                <template #head(name)="scope">
                    <div>{{ getTFieldTitle('name') }}</div>
                    <b-form-input
                        id="filter-input"
                        v-model="filter.name"
                        type="search">
                    </b-form-input>
                </template>
                <template #head(receipt_prev)="scope">
                    <div>
                        {{ receiv_prev }}
                    </div>
                </template>
                <template #head(plan)="scope">
                    <div>
                        {{ plan }}
                    </div>
                </template>

                <template #cell(check)="data">
                    <template v-if="selVersion && selVersion.attribute
                    && [2, 3].includes(userLevel) && operationCode.includes('uebp_agree')
                    && [4, 5].includes(data.item.type) && !data.item.pprChild
                    && data.item.status && [5, 36].includes(data.item.status.code)">
                        <b-form-checkbox v-model="data.item.check"
                                         :disabled="!newStatus(data.item)">
                        </b-form-checkbox>
                    </template>
                </template>
                <template #cell(action)="data">
                    <b-button v-if="data.item.type === 3" @click="openChilds(data.item)" >
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open" ></i>
                        <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
                    </b-button>
                </template>
                <template #cell(abp)="data">
                    <div v-if="data.item.type === 3" class="text-right">
                        {{ data.value }}
                    </div>
                    <b-button v-if="data.item.type === 4 && data.item.hasChild"
                        @click="openChilds(data.item)">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
                        <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
                    </b-button>
                </template>
                <template #cell(prg)="data">
                    <div v-if="data.item.type === 4" class="text-right">
                        {{ data.value }}
                    </div>
                    <b-button v-if="data.item.type === 5 && data.item.hasChild" @click="openChilds(data.item)">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
                        <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
                    </b-button>
                </template>
                <template #cell(ppr)="data">
                    <div v-if="data.item.type === 5" class="text-right">
                        {{ data.value }}
                    </div>
                </template>
                <template #cell(spf)="data">
                    <div v-if="data.item.type === 6" class="text-right">
                        {{ data.value }}
                    </div>
                </template>
                <template #cell(name)="data">
                    <template v-if="data.item.id < 0 && [4, 5, 6].includes(data.item.type)">
                        <template v-if="data.item.id < 0 && data.item.type === 4">
                            <b-form-group :class="{ 'with-warning': existElem(data.item) }">
                                <multiselect v-model="data.item.code"
                                             track-by="text"
                                             label="text"
                                             :options="prgList"
                                             :searchable="true"
                                             :show-labels="false"
                                             @input="inputPrg(data.item)">
                                </multiselect>
                                <template v-if="existElem(data.item)">
                                    <div class="form-tooltip">
                                        <i class="icon icon-danger"></i>
                                        <div class="form-tooltip-content">
                                            <p class="red mb-2">
                                                {{ getSmsTitle('attention') }} <br />
                                                {{ getCdfTitle('prg_present_in_list') }}
                                            </p>
                                        </div>
                                    </div>
                                </template>
                            </b-form-group>
                        </template>
                        <template v-if="data.item.id < 0 && data.item.type === 5">
                            <b-form-group :class="{ 'with-warning': existElem(data.item) }">
                                <multiselect v-model="data.item.code"
                                             track-by="text"
                                             label="text"
                                             :options="pprList"
                                             :searchable="true"
                                             :show-labels="false"
                                             @input="inputPpr(data.item)">
                                </multiselect>
                                <template v-if="existElem(data.item)">
                                    <div class="form-tooltip">
                                        <i class="icon icon-danger"></i>
                                        <div class="form-tooltip-content">
                                            <p class="red mb-2">
                                                {{ getSmsTitle('attention') }} <br />
                                                {{ getCdfTitle('prg_ppr_present_in_list') }}
                                            </p>
                                        </div>
                                    </div>
                                </template>
                            </b-form-group>
                        </template>
                        <template v-if="data.item.id < 0 && data.item.type === 6">
                            <b-form-group :class="{ 'with-warning': existElem(data.item) }">
                                <multiselect v-model="data.item.code"
                                             track-by="text"
                                             label="text"
                                             :options="spfList"
                                             :searchable="true"
                                             :show-labels="false"
                                             @input="inputSpf(data.item)">
                                </multiselect>
                                <template v-if="existElem(data.item)">
                                    <div class="form-tooltip">
                                        <i class="icon icon-danger"></i>
                                        <div class="form-tooltip-content">
                                            <p class="red mb-2">
                                                {{ getSmsTitle('attention') }} <br />
                                                {{ getCdfTitle('prg_ppr_spf_present_in_list') }}
                                            </p>
                                        </div>
                                    </div>
                                </template>
                            </b-form-group>
                        </template>
                    </template>
                    <div v-else>{{ data.item.name }}</div>
                </template>
                <template #cell()="data">
                    <div class="text-right">
                        {{ $n(toNum(data.value)) }}
                    </div>
                </template>
                <template #cell(request1)="data">
                    <template v-if="data.item.type === 6 && toNum(data.value) > 0">
                        <a href="#" @click="clkShowGu(data.item, 0)">
                            {{ $n(toNum(data.value)) }}</a>
                    </template>
                    <template v-else>{{ $n(toNum(data.value)) }}</template>
                </template>
                <template #cell(request2)="data">
                    <template v-if="data.item.type === 6 && toNum(data.value) > 0">
                        <a href="#" @click="clkShowGu(data.item, 1)">
                            {{ $n(toNum(data.value)) }}</a>
                    </template>
                    <template v-else>{{ $n(toNum(data.value)) }}</template>
                </template>
                <template #cell(request3)="data">
                    <template v-if="data.item.type === 6 && toNum(data.value) > 0">
                        <a href="#" @click="clkShowGu(data.item, 2)">
                            {{ $n(toNum(data.value)) }}</a>
                    </template>
                    <template v-else>{{ $n(toNum(data.value)) }}</template>
                </template>
                <template #cell(plan1)="data">
                    <template v-if="editing && data.item.editable && data.item.type === 6">
                        <b-form-input class="text-right"
                                      :value="$n(data.item.plan1)"
                                      :disabled="!editing || firstVariant"
                                      @change="(v) => (data.item.plan1 = v)"
                                      @keyup.enter.exact="keyup13"
                                      @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                      @blur="inputFixed( data.item, 'plan1', data.item.plan1, 1)">
                        </b-form-input>
                    </template>
                    <template v-else>
                        <div :class="{error: toNum(data.item.plan1) !== toNum(data.item.request1) }">
                            <div class="text-right">{{ $n(toNum(data.value)) }}</div>
                            <template v-if="toNum(data.item.plan1) !== toNum(data.item.request1)
                            && (data.item.type === 5 || (data.item.type === 4 && !data.item.pprChild))">
                                <i class="icon icon-danger"></i>
                                <div class="pop-up">
                                    <p class="red-text">{{ getSmsTitle('attention') }}</p>
                                    <p>{{ getCdfTitle('discrepancy_ABP_version') }}</p>
                                </div>
                            </template>
                        </div>
                    </template>
                </template>
                <template #cell(plan2)="data">
                    <template v-if="editing && data.item.editable && data.item.type === 6 && selVersion.data_type < 4">
                        <b-form-input class="text-right"
                                       :value="$n(data.item.plan2)"
                                       :disabled="!editing || firstVariant"
                                       @change="(v) => (data.item.plan2 = v)"
                                       @keyup.enter.exact="keyup13"
                                       @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                       @blur="inputFixed(data.item, 'plan2', data.item.plan2, 1)">
                        </b-form-input>
                    </template>
                    <template v-else>
                        <div :class="{error: toNum(data.item.plan2) !== toNum(data.item.request2)}">
                            <div class="text-right">{{ $n(toNum(data.value)) }}</div>
                            <template v-if=" toNum(data.item.plan2) !== toNum(data.item.request2) &&
                                    (data.item.type === 5 || (data.item.type === 4 && !data.item.pprChild))">
                                <i class="icon icon-danger"></i>
                                <div class="pop-up">
                                    <p class="red-text">{{ getSmsTitle('attention') }}</p>
                                    <p>{{ getCdfTitle('discrepancy_ABP_version') }}</p>
                                </div>
                            </template>
                        </div>
                    </template>
                </template>
                <template #cell(plan3)="data">
                    <template v-if="editing && data.item.editable && data.item.type === 6 && selVersion.data_type < 4">
                        <b-form-input class="text-right"
                                      :value="$n(data.item.plan3)"
                                      :disabled="!editing || firstVariant"
                                      @change="(v) => (data.item.plan3 = v)"
                                      @keyup.enter.exact="keyup13"
                                      @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                      @blur="inputFixed(data.item, 'plan3', data.item.plan3, 1)">
                        </b-form-input>
                    </template>
                    <template v-else>
                        <div :class="{error: toNum(data.item.plan3) !== toNum(data.item.request3)}">
                            <div class="text-right">{{ $n(toNum(data.value)) }}</div>
                            <template v-if="toNum(data.item.plan3) !== toNum(data.item.request3) &&
                                    (data.item.type === 5 || (data.item.type === 4 && !data.item.pprChild))">
                                <i class="icon icon-danger"></i>
                                <div class="pop-up">
                                    <p class="red-text">{{ getSmsTitle('attention') }}</p>
                                    <p>{{ getCdfTitle('discrepancy_ABP_version') }}</p>
                                </div>
                            </template>
                        </div>
                    </template>
                </template>
                <template #cell(status)="data">
                    <template v-if="[4, 5].includes(data.item.type) && !data.item.pprChild">
                        <div>
                            <template v-if="data.item.status">
                                {{ data.item.status.name }}
                            </template>
                            <template v-if="!data.item.status && newStatus(data.item)">
                                {{ getCdfTitle('request_app') }}
                            </template>
                        </div>
                    </template>
                    <template v-if="[6].includes(data.item.type) && data.item.status && data.item.status.hasOwnProperty('agr_id')">
                        <div class="d-flex justify-content-center">
                            <template v-if="data.item.status.code === 1">
                                <span class="rounded-status status-success"></span> <!-- соотвествует -->
                            </template>
                            <template v-else-if="data.item.status.code === 0">
                                <span class="rounded-status status-danger"></span> <!-- не соответствует -->
                            </template>
                        </div>
                    </template>
                </template>
                <template #cell(comments)="data">
                    <div v-if="[4, 5].includes(data.item.type) && !data.item.pprChild && data.item.status && data.item.status.comment.length > 0">
                        {{ data.item.status.comment }}
                    </div>
                    <div v-if="[6].includes(data.item.type)">
                        <template v-if="!data.item.id || data.item.id > 0">
                            <template v-if="data.item.status && data.item.status.agr_id > 0">
                                <template v-if="data.item.status.comment.length > 0">
                                    <template v-if="data.item.parent_status && data.item.parent_status.code === 36">
                                        {{ data.item.status.comment }}
                                    </template>
                                    <template v-else>
                                        <a href="#"
                                           v-if="[2, 3].includes(userLevel) && operationCode.includes('uebp_agree')"
                                           @click="agreeSpfEvent(data.item)">
                                            {{ data.item.status.comment }}
                                        </a>
                                        <div v-else>
                                            {{ data.item.status.comment }}
                                        </div>
                                    </template>
                                </template>
                                <template v-else>
                                    <template v-if="!(data.item.parent_status && data.item.parent_status.code === 36)">
                                        <i class="icon icon-pencil-edit text-primary pointer"
                                           v-if="selVersion && selVersion.attribute
                                           && [2, 3].includes(userLevel)
                                           && operationCode.includes('uebp_agree')"
                                           @click="agreeSpfEvent(data.item)">
                                        </i>
                                    </template>
                                </template>
                            </template>
                            <template v-else>
                                <template v-if="!(data.item.parent_status && data.item.parent_status.code === 36)">
                                    <i class="icon icon-plus-circle add-button"
                                       v-if="selVersion && selVersion.attribute
                                             && [2, 3].includes(userLevel) && operationCode.includes('uebp_agree')"
                                       @click="agreeSpfEvent(data.item)"></i>
                                </template>
                            </template>
                        </template>
                    </div>
                </template>
                <template #cell(history)="data">
                    <b-dropdown dropleft class="more">
                        <template v-slot:button-content>
                            <i class="icon icon-more"></i>
                        </template>
                        <b-dropdown-item v-if="[4, 5].includes(data.item.type)
                                               && data.item.status && data.item.status.agr_id"
                                         @click="commentClk(data.item)">
                            {{ getCardTitle('sector_5', 'title') }}
                        </b-dropdown-item>
                        <b-dropdown-item v-if="data.item.type === 6  && data.item.status && data.item.status.agr_id"
                                         @click="commentSpf(data.item)">
                            {{ getCardTitle('sector_5', 'title') }}
                        </b-dropdown-item>
                        <template v-if="editing">
                            <template v-if="data.item.type === 3 && !firstVariant">
                                <b-dropdown-item @click="addPrg(data.item)">
                                    {{ getCdfTitle('add_prg') }}
                                </b-dropdown-item>
                            </template>
                            <template v-if="data.item.type === 4 && data.item.pprChild && !firstVariant">
                                <b-dropdown-item @click="addPpr(data.item)">
                                    {{ getCdfTitle('add_ppr') }}
                                </b-dropdown-item>
                            </template>
                            <template v-if="!firstVariant && [4, 5].includes(data.item.type)
                                            && !data.item.pprChild
                                            && ((data.item.status && data.item.status.code !== 22)
                                            || !data.item.status)">
                                <b-dropdown-item v-if="[2, 3].includes(userLevel)"
                                                 @click="addSpf(data.item)">
                                    {{ getCdfTitle('add_spf') }}
                                </b-dropdown-item>
                            </template>
                            <b-dropdown-item v-if="[4, 5].includes(data.item.type)
                                                   && ((data.item.status && data.item.status.code !== 22)
                                                   || !data.item.status)
                                                   && checkStatusPpr(data.item) === 0 && !firstVariant"
                                             @click="deleteDatas(data.item)">
                                {{ getBipTitle('btn.delete') }}
                            </b-dropdown-item>
                            <b-dropdown-item v-if="[6].includes(data.item.type)
                                                   && checkStatusPpr(data.item) === 0
                                                   && !firstVariant"
                                             @click="deleteDatas(data.item)">
                                {{ getBipTitle('btn.delete') }}
                            </b-dropdown-item>
                        </template>
                    </b-dropdown>
                </template>
                <template #bottom-row="data">
                    <td class="text-right" colspan="7">
                        {{ getTFieldTitle('total').toUpperCase() + ' (' + getCardTitle('sector_1', 'thousand_tg').toUpperCase() + ')' }}
                    </td>
                    <td class="text-right">{{ $n(total("receipt_prev")) }}</td>
                    <td class="text-right">{{ $n(total("plan")) }}</td>
                    <td class="text-right">{{ $n(total("request1")) }}</td>
                    <td class="text-right">{{ $n(total("plan1")) }}</td>
                    <td class="text-right">{{ $n(total("request2")) }}</td>
                    <td class="text-right">{{ $n(total("plan2")) }}</td>
                    <td class="text-right">{{ $n(total("request3")) }}</td>
                    <td class="text-right">{{ $n(total("plan3")) }}</td>
                </template>
            </b-table>
            <loading :active="loading"
                     is-full-screen
                     spinner="bar-fade-scale"
                     color="#6495ED"/>
        </div>

        <c-budg-agr-modal ref="refAgrModal" :agrBtnLst="agrBtnLst" @agrClk="agrClick"></c-budg-agr-modal>
        <c-budg-agr-hist-modal ref="refHistModal"></c-budg-agr-hist-modal>
        <c-guelevel-modal ref="refGuLvlModal"></c-guelevel-modal>
        <c-budg-agr-spf-modal ref="refSpfModal" :spf="spf" @saveSpf="saveSpf"></c-budg-agr-spf-modal>
    </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import store from "@/services/store";
import VueElementLoading from "vue-element-loading";
import CBudgetAgreementModal from "@/modules/budget-request/components/budget-sum-calc/budget-agreement-modal.vue";
import CBudgetAgreementHistModal from "@/modules/budget-request/components/budget-sum-calc/budget-agreement-hist-modal.vue";
import CBudgetSumCalcGuLevelModal from "../budget-request/components/budget-sum-calc/budget-sum-calc-gulevel-modal";
import CBudgetAgreementSpfModal from "@/modules/budget/bip/budget-agreement-spf-modal.vue";
import {
    makeToast,
    years,
    sortByField,
    getRowKey,
    filterVersion,
    getNumber,
    versionByRegion,
    setCurVariant,
    postData,
    getBipTitle,
    getCardTitle,
    getFilterTitle,
    getTFieldTitle,
    getSmsTitle,
    itemNameLocale,
    nameLocale, getCommonTitle
} from "@/modules/budget/bip/bip-types";
import i18nService from "@/services/i18n";

export default {
    name: "CostDataForm",
    components: {
        loading: VueElementLoading,
        "c-budg-agr-modal": CBudgetAgreementModal,
        "c-budg-agr-hist-modal": CBudgetAgreementHistModal,
        "c-guelevel-modal": CBudgetSumCalcGuLevelModal,
        "c-budg-agr-spf-modal": CBudgetAgreementSpfModal
    },
    props: {
        userLevel: undefined,
        location: undefined,
        regionBudgetList: {
            type: Array,
            default: () => []
        },
        versionList: {
            type: Array,
            default: () => []
        },
        dataTypeList: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            mode_code: "bp",
            openFilterWindow: false,
            calcFlds: [
                "receipt_prev",
                "plan",
                "request1",
                "plan1",
                "request2",
                "plan2",
                "request3",
                "plan3",
                "request1_1",
                "plan1_1",
                "request2_1",
                "plan2_1",
                "request3_1",
                "plan3_1",
                "request1_2",
                "plan1_2",
                "request2_2",
                "plan2_2",
                "request3_2",
                "plan3_2"
            ],

            budgetForm: [],
            budgetTable: [],
            pprMap: new Map(),
            valMap: new Map(),

            dictTree: null,
            planPeriod: null,
            selRegionBudget: null,
            selDataType: null,
            selVersion: null,
            selAbp: null,

            prgList: [],
            pprList: [],
            spfList: [],

            agrBtnLst: { back: [], forward: [] },
            spf: {},
            operationCode: [],

            idPrg: 0,
            idPpr: 0,
            idSpf: 0,
            filter: {
                abp: null,
                prg: null,
                ppr: null,
                spf: null,
                name: null,
                search: null,
                on: ["filter", "abp", "prg", "ppr", "spf", "name"],
            },
            open: false,
            check: false,
            loading: false
        };
    },

    created() {
        console.log(new Date().toISOString(), "cdf created");

        this.$watch("filter.abp", this.filterUpdate);
        this.$watch("filter.prg", this.filterUpdate);
        this.$watch("filter.ppr", this.filterUpdate);
        this.$watch("filter.spf", this.filterUpdate);
        this.$watch("filter.name", this.filterUpdate);

        this.$watch("regionBudgetList", (value) => {
            if (value.length > 0) {
                console.log(new Date().toISOString(), "cdf regionBudgetList = ", value.length);
                this.selRegionBudget = this.regionBudgetList[0];
                versionByRegion(this);
            }
        });

        this.$watch("planPeriod", (value) => {
            if (value) {
                console.log(new Date().toISOString(), "cdf planPeriod = ", value);
                setCurVariant(this);
            }
        });
        this.$watch("selRegionBudget", async (value) => {
            if (value) {
                console.log(new Date().toISOString(), "cdf selRegionBudget = ", value);
                setCurVariant(this);
            }
        });
        this.$watch("selDataType", (value) => {
            if (value) {
                console.log(new Date().toISOString(), "cdf selDataType = ", value);
                setCurVariant(this, true);
            }
        });
        this.$watch("selVersion", (value) => {
            this.selAbp = null;
            if (value && !this.loading) {
                console.log(new Date().toISOString(), "cdf selVersion", this.selVersion);
                this.loading = true;
                this.updateDatas();
            }
        });

        this.$watch("check", this.checkAll);

        this.loadOperations();
    },

    beforeUpdate() {
        for (const row of this.budgetForm) {
            if ([0, 3, 4, 5].includes(row.type) && row.hasChild) {
                row._rowVariant = "info";
            }
            if ([6].includes(row.type) && !row.hasChild) {
                this.$set(row, "editable", true);
                if (row.status && row.status.code === 22) {
                    this.$set(row, "editable", false);
                }
            }
        }
    },

    computed: {
        userUiid() {
            return store.state.user.sub;
        },

        editing() {
            return ([2, 3].includes(this.userLevel) && this.selVersion && this.selVersion.attribute);
        },

        reading() {
            return ([2, 3].includes(this.userLevel) && this.selVersion);
        },

        yearsFilter() {
            return years();
        },

        dataTypeFilter() {
            return this.dataTypeList.filter((row) =>
                ["1", "2", "3", "4"].includes(row.code),
            );
        },

        versionFilter() {
            const result = filterVersion(this);
            for (const row of result) {
                if (row.date_start === null) {
                    this.$set(row, "ds", row.year + "-12-31");
                } else {
                    this.$set(row, "ds", row.date_start.toString());
                }
            }
            return result;
        }, // список для фильтра по версиям

        firstVariant() {
            if (this.selDataType && parseInt(this.selDataType.code) !== 1) {
                return false;
            }
            if (this.versionFilter) {
                let list = this.versionFilter.filter(
                    (row) => row.data_type === 1,
                );

                if (list.length > 0) {
                    list = list.sort(sortByField("ds"));
                    let first = list[0];
                    list = list.filter((row) => row.ds === first.ds);
                    if (list.length > 1) {
                        list = list.sort(sortByField("id"));
                        first = list[0];
                    }
                    return this.selVersion.variant_uuid === first.variant_uuid;
                }
            }
            return false;
        },

        tableFields() {
            return [
                {
                    key: "check",
                    label: "",
                    class: "toggle-show"
                },
                {
                    key: "action",
                    label: "",
                    class: "toggle-show"
                },
                {
                    key: "abp",
                    label: getFilterTitle(this, 'ABP'),
                    class: "toggle-show"
                },
                {
                    key: "prg",
                    label: getTFieldTitle(this, 'prg'),
                    class: "toggle-show"
                },
                {
                    key: "ppr",
                    label: getTFieldTitle(this, 'ppr')
                },
                {
                    key: "spf",
                    label: getTFieldTitle(this, 'spf')
                },
                {
                    key: "name",
                    label: getTFieldTitle(this, 'name'),
                },
                {
                    key: "receipt_prev",
                    label: ""
                },
                {
                    key: "plan",
                    label: ""
                },
                {
                    key: "request1",
                    label: getBipTitle(this, 'report.BZ_ABP'),
                    variant: "info"
                },
                {
                    key: "plan1",
                    label: this.getCdfTitle('agreed_UEBP_OEBP')
                },
                {
                    key: "request2",
                    label: getBipTitle(this, 'report.BZ_ABP'),
                    variant: "info"
                },
                {
                    key: "plan2",
                    label: this.getCdfTitle('agreed_UEBP_OEBP')
                },
                {
                    key: "request3",
                    label: getBipTitle(this, 'report.BZ_ABP'),
                    variant: "info"
                },
                {
                    key: "plan3",
                    label: this.getCdfTitle('agreed_UEBP_OEBP')
                },
                {
                    key: "status",
                    label: getFilterTitle(this, 'status')
                },
                {
                    key: "comments",
                    label: getTFieldTitle(this, 'comments')
                },
                {
                    key: "history",
                    label: ""
                },
                {
                    key: "filter",
                    label: "filter",
                    thClass: "d-none",
                    tdClass: "d-none"
                }
            ]
        },

        receiv_prev() {
            switch (`${i18nService.locale}`) {
                case 'ru':
                    return 'Исполнение за ' + (parseInt(this.planPeriod.year) - 2) + ' год'
                    break;
                case 'kk':
                    return (parseInt(this.planPeriod.year) - 2) + ' жылғы атқарылуы'
                    break;
                case 'en':
                    return 'Execution for ' + (parseInt(this.planPeriod.year) - 2) + ' year'
                    break;
                default:
                    break;
            }
            return 'Исполнение за ' + (parseInt(this.planPeriod.year) - 2) + ' год';
        },

        plan() {
            switch (`${i18nService.locale}`) {
                case 'ru':
                    return 'Уточненный план на 1 апреля ' + (parseInt(this.planPeriod.year) - 1)
                    break;
                case 'kk':
                    return (parseInt(this.planPeriod.year) - 1) + ' жылғы 1 сәуірге арналған нақтыланған жоспар'
                    break;
                case 'en':
                    return 'Adjusted plan for April 1 ' + (parseInt(this.planPeriod.year) - 1)
                    break;
                default:
                    break;
            }
            return 'Уточненный план на 1 апреля ' + (parseInt(this.planPeriod.year) - 1);
        }
    },

    methods: {
        async addAbp() {
            await this.prepareForm();
            await this.rememberDatas();
            await this.changeFields();
            this.chgData();
        },

        addPrg(data) {
            const item = {
                id: --this.idPrg,
                parent_id: data.id,
                gr: data.gr,
                pgr: data.pgr,
                abp: data.abp,
                prg: null,
                ppr: null,
                spf: null,
                code: null,
                type: 4,
                visible: true,
                edited: false,
                open: true,
            };
            this.updateIndex(data, item);

            for (const abp of this.dictTree) {
                if (abp.abp === item.abp) {
                    this.prgList = abp.value.child;
                }
            }
        },

        addPpr(data) {
            const item = {
                id: --this.idPpr,
                parent_id: data.id,
                gr: data.gr,
                pgr: data.pgr,
                abp: data.abp,
                prg: data.prg,
                ppr: null,
                spf: null,
                code: null,
                type: 5,
                visible: true,
                edited: false,
                open: true,
            };
            this.updateIndex(data, item);

            for (const abp of this.dictTree) {
                if (abp.abp === item.abp) {
                    const prgList = abp.value.child;
                    for (const prg of prgList) {
                        if (prg.prg === item.prg) {
                            this.pprList = prg.value.child;
                        }
                    }
                }
            }
        },

        addSpf(data) {
            const item = {
                id: --this.idSpf,
                parent_id: data.id,
                gr: data.gr,
                pgr: data.pgr,
                abp: data.abp,
                prg: data.prg,
                ppr: data.ppr,
                spf: null,
                code: null,
                type: 6,
                visible: true,
                edited: false,
            };
            this.updateIndex(data, item);
        },

        async agrClick(data) {
            await this.saveDatas();

            data.userId = this.userUiid;
            data.variant = this.selVersion.variant_uuid;
            data.region = this.selRegionBudget.code;
            data.data_type = this.selDataType.code;

            const newAgree = [];
            for (const el of this.selectArr) {
                const elem =  Object.assign(el, data);

                const spfAgree = [];
                for (const spf of this.budgetForm.filter(row => row.type === 6 && row.visible
                    && row.status && row.status.agr_id > 0)) {

                    if (getRowKey(spf, ['abp', 'prg', 'ppr']) === getRowKey(elem, ['abp', 'prg', 'ppr'])) {
                        this.$set(spf, 'user_id', this.userUiid);
                        this.$set(spf, 'cur_year', this.selVersion.year);
                        this.$set(spf, 'region', this.selVersion.region_code);
                        this.$set(spf, 'data_type', this.selVersion.data_type);
                        this.$set(spf, 'variant', this.selVersion.variant_uuid);
                        this.$set(spf, 'hist', true);
                        spfAgree.push(spf);
                    }
                }
                this.$set(elem, 'spfAgree', spfAgree);
                newAgree.push(elem);
            }
            postData('/api-py/set-agreement-cost-with-spf', newAgree)
                .then((response) => {
                    this.updateDatas();
                    for (const res of response) {
                        if (res.result === "success") {
                            makeToast(this, "success", getSmsTitle(this, 'saving'),
                                getSmsTitle(this, 'datas_saved'));
                        } else {
                            makeToast(this, "danger", getSmsTitle(this, 'error_save'),
                                `${res.errTxt}`);
                        }
                    }
                });
        },

        // кнопка действия
        async agreementEvent() {
            this.selectArr = [];
            this.agrBtnLst = { back: [], forward: [] };
            let firstStatus = null;

            for (const row of this.budgetForm) {
                if (row.check && [4, 5].includes(row.type) && !row.pprChild && row.status) {
                    // без подпрограммы
                    if (firstStatus === null) {
                        firstStatus = row.status.code;
                    }
                    if (firstStatus !== row.status.code) {
                        makeToast(this, "danger", getSmsTitle(this, 'coordination'),
                            this.getCdfTitle('sel_prg_ppr_same_status'));
                        return;
                    }
                    const agrObj = {
                        agr_id: row.status.agr_id,
                        cur_year: this.planPeriod.year,
                        data_type: this.selDataType,
                        abp: row.abp,
                        prg: row.prg,
                        ppr: row.ppr
                    };
                    this.selectArr.push(agrObj);
                }
            }
            if (this.selectArr.length === 0) {
                makeToast(this, "warning", getSmsTitle(this, 'coordination'),
                    this.getCdfTitle('no_selected_prg_ppr'));
                return;
            }

            const params = {
                modeCode: this.mode_code,
                operationCode: this.operationCode,
                agrCode: firstStatus
            };
            let result = [];
            try {
                result = await fetch(`/api-py/get-agreement-step-next-back/${encodeURI(JSON.stringify(params))}`)
                    .then((response) => response.json());
            } catch (error) {
                makeToast(this, "danger", getSmsTitle(this, 'attention'),
                    getSmsTitle(this, 'error_request') + '/get-agreement-step-next-back');
                return;
            }
            if (result.length === 0) {
                makeToast(this, "warning", getSmsTitle(this, 'coordination'),
                    getSmsTitle(this, 'no_approval_steps_available'));
                return;
            }
            for (const el of result) {
                itemNameLocale(el);
                if (el['stepType'] === 1) {
                    this.agrBtnLst.back.push(el);
                } else {
                    this.agrBtnLst.forward.push(el);
                }
            }

            this.$refs.refAgrModal.showEvent();
        },

        async agreeSpfEvent(data) {
            if (!data.hasOwnProperty('status')) {
                this.$set(data, 'status', {});
                this.$set(data.status, 'agr_id', null);
                this.$set(data.status, 'code', undefined);
                this.$set(data.status, 'comment_txt', '');
                this.$set(data.status, 'comment', '');
            }
            this.spf = Object.assign({}, data);

            this.$refs.refSpfModal.showEvent();
        },

        // отмечает все ветки
        checkAll() {
            for (const row of this.budgetForm) {
                row.check = this.check;
            }
        },

        // показать ГУ
        clkShowGu(obj, prog) {
            const param = {
                year: this.planPeriod.year + prog,
                variant: this.selVersion.variant_uuid,
                abp: this.selAbp.abp,
                curYear: this.planPeriod.year,
                dataType: this.selDataType.code,
                prg: obj.prg,
                ppr: obj.ppr,
                spf: obj.spf
            };
            // eslint-disable-next-line
            // @ts-ignore
            this.$refs.refGuLvlModal.showEvent(param);
        },

        // древовидную выборку преобразовывает в таблицу (для отображения)
        createTable(elem, parentId) {
            // создание таблицы на основе дерева
            const that = this;
            const item = Object.assign({}, elem);
            itemNameLocale(item);

            if (item.status) {
                itemNameLocale(item.status);
            }
            // установка id родителя
            that.$set(item, "parent_id", parentId);
            // флажок для кнопок "Действия"
            that.$set(item, "check", false);
            // установка индекса строки
            that.$set(item, "index", that.budgetForm.length);
            // добавление элемента в таблицу
            that.$set(that.budgetForm, that.budgetForm.length, item);
            // поиск родителя
            Object.defineProperty(item, "parent", {
                get: function () {
                    for (const row of that.budgetForm) {
                        if (item.parent_id === row.id) {
                            return row;
                        }
                    }
                    return null;
                },
            });
            // hasChild - признак наличия подветок
            if (item.hasOwnProperty("child") && item.child.length > 0) {
                that.$set(item, "hasChild", true);
            }
            // открыть все ветки кроме уровня SPF
            if ([4, 5].includes(item.type) && !item.pprChild) {
                that.$set(item, "open", false);
            } else {
                that.$set(item, "open", true);
            }
            // показать все ветки кроме уровня SPF
            if (item.type === 6) {
                that.$set(item, "visible", false);
            } else {
                that.$set(item, "visible", true);
            }

            if (!item.hasChild) {
                that.calcFlds.forEach((field) => {
                    that.$set(item, field, getNumber(item[field]));
                });

                if ([4, 5, 6].includes(item.type)) {
                    that.pprMap.set(getRowKey(item, ["abp", "prg", "ppr", "spf"]), item);
                }
            } else {
                that.calcFlds.forEach((field) => {
                    Object.defineProperty(item, field, {
                        get: function () {
                            return that.reSum(item, field);
                        },
                    });
                });

                for (const ch of item.child) {
                    if ([4, 5].includes(item.type)) {
                        this.$set(ch.value, "develop_type", item.develop_type);
                    }
                    that.createTable(ch.value, item.id);
                }
                delete item.child;
            }
        },

        // сравнивает введенные данные с ранее сохраненные и формирует массив новых записей для сохранения в БД
        compareDatas() {
            const saveDatas = [];
            for (const row of this.pprMap.values()) {
                if (row.edited) {
                    const val = this.valMap.get(getRowKey(row, ["abp", "prg", "ppr", "spf"]));

                    if (!val || JSON.stringify(row) !== val) {
                        for (let i=0; i<3; i++) {
                            saveDatas.push(
                                this.newCost(
                                    row.type,
                                    row.gr ? row.gr : null,
                                    row.pgr ? row.pgr : null,
                                    row.abp,
                                    row.prg,
                                    row.ppr,
                                    row.spf,
                                    parseFloat(row['plan' + (i + 1)]),
                                    parseInt(this.planPeriod.year + i),
                                    parseInt(this.selDataType.code),
                                    this.selRegionBudget.code,
                                    this.selVersion.variant_uuid,
                                    this.userUiid)
                            );
                        }
                        if (row.id < 0) {
                            this.$set(row, "id", 0);
                        }
                    }
                }
            }
            return saveDatas;
        },

        commentClk(row) {
            if (row.status && row.status.agr_id > 0) {
                const agrObj = {
                    id: row.status.agr_id,
                    type: row.type,
                    status: row.status.code,
                    updateDate: row.status.update_date,
                    userId: row.status.user_id,
                    abp: row.abp,
                    prg: row.prg,
                    ppr: row.ppr,
                    status_lang: {}
                    // this.$set(agrObj.status_lang, 'name_ru', row.status.name_ru);
                    // this.$set(agrObj.status_lang, 'name_kk', row.status.name_kk);
                    // this.$set(agrObj.status_lang, 'name_en', row.status.name_en);
                }

                this.$refs.refHistModal.showEvent(Object.assign(row.status, agrObj));
            }
        },

        commentSpf(item) {
            const agrObj = {
                id: item.status.agr_id,
                status: item.status.code,
                type: 6
            }
            this.$refs.refHistModal.showEvent(Object.assign(agrObj, item.status));
        },

        changeFields() {
            for (const row of this.budgetForm) {
                if ([3, 4, 5].includes(row.type)) {
                    row.visible = true;
                }
                if (!row.hasChild) {
                    [
                        "plan1",
                        "plan2",
                        "plan3",
                        "request1",
                        "request2",
                        "request3",
                    ].forEach((field) => {
                        const f = field + "_" + this.selDataType.code;
                        if (row.hasOwnProperty(f)) {
                            this.$set(row, field, parseFloat(row[f]));
                        } else {
                            this.$set(row, field, 0);
                        }
                    });
                }
            }
            this.deleteNulls();
            this.chgData();
        },

        chgData() {
            if (this.selVersion) {
                const data = {
                    version: this.selVersion,
                    abp: (this.selAbp === null ? 0 : this.selAbp.abp),
                    datas: this.selAbp,
                    pprMap: this.pprMap
                };
                this.$emit("curFilter", data);
            }
        },

        checkStatusPpr(item) {
            let status = 0;
            const list = this.budgetForm.filter(row => row.status && [22].includes(row.status.code));

            if (item.type === 4) {
                for (const row of list) {
                    if (item.id === row.parent_id) {
                        status++;
                    }
                }
            }

            if (item.type === 6) {
                for (const row of list) {
                    if (item.parent_id === row.id) {
                        status++;
                    }
                }
            }
            return status;
        },

        async deleteDatas(item) {
            let sms = "";
            switch (item.type) {
                case 3:
                    switch (`${i18nService.locale}`) {
                        case 'ru':
                            sms = '"Согласовано УЭБП/ОЭБП" АБП=' + item.abp
                                + ", удаленные данные восстановлению не подлежат. Удалить данные?";
                            break;
                        case 'kk':
                            sms = '"ЭБЖБ келісілді" ББӘ=' + item.abp
                                + ', жойылған деректер қалпына келтірілмейді. Деректерді жою керек пе?'
                            break;
                        case 'en':
                            sms = '"Agreed by UEBP/OEBP" ABP=' + item.abp
                                + ', deleted data cannot be restored. Delete data?'
                            break;
                        default:
                            break;
                    }
                    break;
                case 4:
                    switch (`${i18nService.locale}`) {
                        case 'ru':
                            sms = '"Согласовано УЭБП/ОЭБП" АБП=' + item.abp + " БП=" + item.prg
                                + ", удаленные данные восстановлению не подлежат. Удалить данные?";
                            break;
                        case 'kk':
                            sms = '"ЭБЖБ келісілді" ББӘ=' + item.abp + " ББ=" + item.prg
                                + ', жойылған деректер қалпына келтірілмейді. Деректерді жою керек пе?'
                            break;
                        case 'en':
                            sms = '"Agreed by UEBP/OEBP" ABP=' + item.abp + " BP=" + item.prg
                                + ', deleted data cannot be restored. Delete data?'
                            break;
                        default:
                            break;
                    }
                    break;
                case 5:
                    switch (`${i18nService.locale}`) {
                        case 'ru':
                            sms = '"Согласовано УЭБП/ОЭБП" АБП=' + item.abp + " БП=" + item.prg + " БПП=" + item.ppr
                                + ", удаленные данные восстановлению не подлежат. Удалить данные?";
                            break;
                        case 'kk':
                            sms = '"ЭБЖБ келісілді" ББӘ=' + item.abp + " ББ=" + item.prg + " БКБ=" + item.ppr
                                + ', жойылған деректер қалпына келтірілмейді. Деректерді жою керек пе?'
                            break;
                        case 'en':
                            sms = '"Agreed by UEBP/OEBP" ABP=' + item.abp + " BP=" + item.prg + " BPP=" + item.ppr
                                + ', deleted data cannot be restored. Delete data?'
                            break;
                        default:
                            break;
                    }
                    break;
                case 6:
                    switch (`${i18nService.locale}`) {
                        case 'ru':
                            sms = '"Согласовано УЭБП/ОЭБП" АБП=' + item.abp + " БП=" + item.prg + " БПП=" + item.ppr + " СПФ=" + item.spf
                                + ", удаленные данные восстановлению не подлежат. Удалить данные?";
                            break;
                        case 'kk':
                            sms = '"ЭБЖБ келісілді" ББӘ=' + item.abp + " ББ=" + item.prg + " БКБ=" + item.ppr + " Ерекшелік=" + item.spf
                                + ', жойылған деректер қалпына келтірілмейді. Деректерді жою керек пе?'
                            break;
                        case 'en':
                            sms = '"Agreed by UEBP/OEBP" ABP=' + item.abp + " BP=" + item.prg + " BPP=" + item.ppr + " SPF=" + item.spf
                                + ', deleted data cannot be restored. Delete data?'
                            break;
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
            await this.$bvModal
                .msgBoxConfirm(sms, {
                    title: getSmsTitle(this, 'confirmation'),
                    size: "lg",
                    buttonSize: "sm",
                    okVariant: "success",
                    okTitle: getBipTitle(this, 'btn.yes'),
                    cancelTitle: getBipTitle(this, 'btn.cancel'),
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true
                }).then((value) => {
                    if (value) {
                        switch (item.type) {
                            case 3:
                                for (const row of this.budgetForm) {
                                    if (row.editable && row.abp === item.abp) {
                                        ["plan1", "plan2", "plan3"].forEach(
                                            (field) => {
                                                this.$set(row, field, 0);
                                            },
                                        );
                                        this.$set(row, "edited", true);
                                        this.$set(row, "edit");
                                    }
                                }
                            case 4:
                                for (const row of this.budgetForm) {
                                    if (
                                        row.editable &&
                                        getRowKey(row, ["abp", "prg"]) ===
                                        getRowKey(item, ["abp", "prg"])
                                    ) {
                                        ["plan1", "plan2", "plan3"].forEach(
                                            (field) => {
                                                this.$set(row, field, 0);
                                            },
                                        );
                                        this.$set(row, "edited", true);
                                    }
                                }
                            case 5:
                                for (const row of this.budgetForm) {
                                    if (row.editable &&
                                        getRowKey(row, ["abp", "prg", "ppr"]) ===
                                        getRowKey(item, ["abp", "prg", "ppr"])
                                    ) {
                                        ["plan1", "plan2", "plan3"].forEach(
                                            (field) => {
                                                this.$set(row, field, 0);
                                            },
                                        );
                                        this.$set(row, "edited", true);
                                    }
                                }
                            case 6:
                                ["plan1", "plan2", "plan3"].forEach((field) => {
                                    this.$set(item, field, 0);
                                });
                                this.$set(item, "edited", true);
                            default:
                                break;
                        }
                        this.saveDatas();
                    }
                }).catch((error) => {
                    makeToast(this, "danger", getSmsTitle(this, 'error_save'), error.toString());
                });
        },

        deleteNulls() {
            for (const row of this.budgetForm) {
                let sum = 0;

                [
                    "receipt_prev",
                    "plan",
                    "plan1",
                    "plan2",
                    "plan3",
                    "request1",
                    "request2",
                    "request3",
                ].forEach((field) => {
                    sum += parseFloat(row[field]);
                });

                if (sum === 0 && row.spf === null) {
                    row.visible = false;
                }
            }
        },

        existElem(item) {
            let count = 0;

            for (const row of this.budgetForm) {
                ["abp", "prg", "ppr", "spf"].forEach((field) => {
                    if (row[field] === undefined || row[field] === "None") {
                        this.$set(row, field, null);
                    }
                });

                if (getRowKey(row, ["abp", "prg", "ppr", "spf"]) ===
                    getRowKey(item, ["abp", "prg", "ppr", "spf"])) {
                    if (item.spf) {
                        count++;
                        continue;
                    }

                    if (row.visible === true) {
                        count++;
                    }
                }
            }

            return count > 1;
        },

        // формирует строку поиска
        filterUpdate() {
            this.filter.search =
                this.isStr(this.filter.abp) +
                this.isStr(this.filter.prg) +
                this.isStr(this.filter.ppr) +
                this.isStr(this.filter.spf) +
                this.isStr(this.filter.name);
            if (this.filter.search.length === 0) {
                this.filter.search = null;
            }
        },

        // форматирует введенное значение до digit цифр после запятой
        inputFixed(item, field, value, digit) {
            this.$set(item, field, getNumber(value, digit));
            this.$set(item, "edited", true);
        },

        inputPrg(item) {
            for (const [key, value] of Object.entries(item.code.value)) {
                this.$set(item, key, value);
            }
            this.$set(item, "name_ru", item.code.name);

            if (!item.code.value.pprChild) {
                this.$set(item, "edited", true);
                this.calcFlds.forEach((field) => {
                    this.$set(item, field, 0);
                });
            } else {
                this.$set(item, "edited", true);
            }

            if (this.existElem(item)) {
                makeToast(this, "danger", this.getCdfTitle('adding_new_entry'),
                    this.getCdfTitle('prg_present_in_list'));
                return;
            }
            this.pprMap.set(getRowKey(item, ["abp", "prg", "ppr", "spf"]), item);
        },

        inputPpr(item) {
            for (const [key, value] of Object.entries(item.code.value)) {
                this.$set(item, key, value);
            }
            this.$set(item, "name_ru", item.code.name);
            this.$set(item, "edited", true);
            this.calcFlds.forEach((field) => {
                this.$set(item, field, 0);
            });

            if (this.existElem(item)) {
                makeToast(this, "danger", this.getCdfTitle('adding_new_entry'),
                    this.getCdfTitle('prg_ppr_present_in_list'));
                return;
            }
            this.pprMap.set(getRowKey(item, ["abp", "prg", "ppr", "spf"]), item);
        },

        inputSpf(item) {
            this.$set(item, "spf", item.code.spf);
            this.$set(item, "name_ru", item.code.name_ru);
            this.$set(item, "edited", true);
            this.calcFlds.forEach((field) => {
                this.$set(item, field, 0);
            });

            if (this.existElem(item)) {
                makeToast(
                    this,
                    "danger", this.getCdfTitle('adding_new_entry'),
                    this.getCdfTitle('prg_ppr_spf_present_in_list'));
                return;
            }
            this.pprMap.set(getRowKey(item, ["abp", "prg", "ppr", "spf"]), item);
        },

        isStr(value) {
            return value === null || value === "None" ? "" : value;
        },

        // вводит по заданному паттерну
        keyPress: function (event, pattern) {
            // const regex = new RegExp('^[0-9]+$');
            // const regex = new RegExp('^-?\\d*\\d{0,9}$');
            // const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(
                !event.charCode ? event.which : event.charCode,
            );
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        },

        // enter работает как tab
        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll("input"); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex((el) =>
                currentNode.isEqualNode(el),
            );
            // focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        },

        async loadDictTree() {
            const params = {
                variant_uuid: this.selVersion.variant_uuid,
                year: this.selVersion.year,
                region_code: this.selVersion.region_code,
                mode_code: this.mode_code,
                operation_code: this.operationCode
            };
            try {
                this.dictTree = await fetch(`/api-py/cost-data-form/${encodeURI(JSON.stringify(params))}`)
                    .then((response) => response.json());
                this.loading = true;
            } catch (error) {
                makeToast(this, "danger", getSmsTitle(this, 'attention'),
                    getSmsTitle(this, 'error_request') + ' /cost-data-form');
            } finally {
                console.log(new Date().toISOString(), "cdf dictTree = ", this.dictTree.length);
                await this.loadSpfList();
            }
        },

        async loadSpfList() {
            try {
                const result = await fetch(`/api-py/get-spf-pkg/` + this.selVersion.variant_uuid)
                    .then((response) => response.json());
                    this.spfList = result['list'];
            } catch (error) {
                makeToast(this, "danger", getSmsTitle(this, 'error_load'),
                    ' /get-spf-pkg' + error.toString());
            } finally {
                nameLocale(this.spfList, 'spf');
                console.log(new Date().toISOString(), "cdf spfList = ", this.spfList.length);
            }
        },

        async loadOperations() {
            let uLevel = 0;
            try {
                const result = await fetch(encodeURI(`/api/um/module/link?user=${this.userUiid}&modulecode=004.003.003`))
                    .then((response) => response.json());

                if (result.operations) {
                    this.operationCode = result.operations;
                }
                if (result.accessLevel) {
                    uLevel = result.accessLevel;
                }
            } catch (error) {
                makeToast(this, "danger", getSmsTitle(this, 'error_load'),
                    ' /api/um/module/link ' + error.toString());
            } finally {
                console.log(new Date().toISOString(), "cdf loadOperations ", this.userUiid, this.operationCode, uLevel);
            }
        },

        // формирует элемент для сохранение в БД
        newCost(type, gr, pgr, abp, prg, ppr, spf,
            value, year, data_type, region, variant, user_name) {
            return {
                type: type,
                gr: gr,
                pgr: pgr,
                abp: abp,
                prg: prg,
                ppr: ppr,
                spf: spf,
                value: value,
                year: year,
                data_type: data_type,
                region: region,
                variant: variant,
                user_name: user_name,
            };
        },

        newStatus(row) {
            return (row.request1 + row.request2 + row.request3 > 0);
        },

        // открывает.закрывает все ветки
        openAll() {
            this.open = !this.open;

            for (const row of this.budgetForm) {
                row.visible = this.open;
                if ([3, 4].includes(row.type)) {
                    row.open = this.open;
                }
                if (!this.open && row.type === 5) {
                    row.open = this.open;
                }
                if ([3].includes(row.type)) {
                    row.visible = true;
                }
            }
            this.deleteNulls();
        },

        // открывает/закрывает ветку до конечного элемента
        openChilds(parent, bool) {
            parent.open = bool !== undefined ? bool : !parent.open;

            for (const row of this.budgetForm) {
                if (parent.id === row.parent_id) {
                    if (
                        [3, 4].includes(row.type) ||
                        (!parent.open && row.type === 5)
                    ) {
                        this.openChilds(row, parent.open);
                    }
                    row.visible = parent.open;
                }
            }
            this.deleteNulls();
        },

        openCorrect() {
            let url_name = "";
            if (this.selVersion.data_type) {
                if (this.selVersion.data_type === 4) {
                    url_name = "correct";
                    window.open(
                        "/#/budget-" +
                        url_name +
                        "?tab=1&version=" +
                        this.selVersion.variant_uuid,
                        "_blank",
                    );
                } else if (this.selVersion.data_type === 3) {
                    url_name = "clarify";
                    window.open(
                        "/#/budget-" +
                        url_name +
                        "?tab=1&version=" +
                        this.selVersion.variant_uuid,
                        "_blank",
                    );
                }
            }
        },

        openFilterByRef(refName) {
            const drop = this.$refs.drop;
            drop.show(true);
            const refItem = this.$refs[refName];
            setTimeout(() => refItem.$el.focus(), 100);
        },

        // подготовка отображения данных
        async prepareForm() {
            this.budgetForm.splice(0);
            await this.createTable(this.selAbp, 0);
        },

        // расставляет сохранненные данные по полям
        async rememberDatas() {
            this.valMap.clear();
            for (const ppr of this.pprMap.values()) {
                this.$set(ppr, "edited", false);
                this.valMap.set(getRowKey(ppr, ["abp", "prg", "ppr", "spf"]), JSON.stringify(ppr));
            }
        },

        // пересчет суммы
        reSum(parent, field) {
            let sum = 0;
            this.budgetForm.forEach((row) => {
                if (
                    row.parent_id === parent.id &&
                    [row.type - 1, row.type - 2].includes(parent.type)
                ) {
                    sum += parseFloat(row[field]);
                }
            });
            return getNumber(sum, 2);
        },

        // задает класс 'is-hidden' заданной строке
        rowClass(item, type) {
            if (!item || type !== "row") {
                return;
            }
            if (!item.visible) {
                return "is-hidden";
            }
        },

        // вызывает сохранение записей
        async saveDatas() {
            const res = this.compareDatas();
            if (res.length > 0) {
                this.$bvModal.msgBoxConfirm(this.getCdfTitle('want_save_data'),
                    {
                        title: getSmsTitle(this, 'confirmation'),
                        size: "lg",
                        buttonSize: "sm",
                        okVariant: "success",
                        okTitle: getBipTitle(this, 'btn.yes'),
                        cancelTitle: getBipTitle(this, 'btn.cancel'),
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true
                    }).then((value) => {
                        if (value) {
                            this.saveVariant(res);
                        }
                }).catch((error) => {
                    makeToast(this, "danger", getSmsTitle(this, 'error_save'),
                        error.toString());
                });
            }
        },

        async saveSpf(data) {
            data.user_id = store.state.user.sub;
            data.cur_year = this.selVersion.year;
            data.region = this.selVersion.region_code;
            data.data_type = this.selVersion.data_type;
            data.variant = this.selVersion.variant_uuid;
            this.$set(data.status, 'hist', false);

            postData('/api-py/set_brftas', data).then((response) => {

                if (response.result === 'success') {
                    makeToast(this, "success", getSmsTitle(this, 'saving'),
                        getSmsTitle(this, 'datas_saved'));

                    const spf = this.pprMap.get(getRowKey(response, ["abp", "prg", "ppr", "spf"]));
                    if (spf) {
                        this.$set(spf, 'status', response);
                        if (response.comment_txt.length > 35) {
                            this.$set(spf.status, 'comment', response.comment_txt.substring(0, 35) + '...');
                        } else {
                            this.$set(spf.status, 'comment', response.comment_txt);
                        }
                    }
                    for (const row of this.budgetTable) {
                        if (row.type === 6 && row.status && row.status.agr_id === response.agr_id) {
                            this.$set(row, 'status', response);
                            if (response.comment_txt.length > 35) {
                                this.$set(row.status, 'comment', response.comment_txt.substring(0, 35) + '...');
                            } else {
                                this.$set(row.status, 'comment', response.comment_txt);
                            }
                            break;
                        }
                    }
                } else {
                    makeToast(this, "danger", getSmsTitle(this, 'attention'),
                        getSmsTitle(this, 'error_save') + '/set_brftas');
                }
            });
        },

        // сохранение записей в БД
        async saveVariant(values) {
            const items = [values[0]];
            for (const el of values) {
                if (el.ppr !== items.at(-1).ppr
                    || el.spf !== items.at(-1).spf) {
                    items.push(el);
                }
            }

            for (let i = 0; i < items.length; i++) {
                if (items[i].spf === null && items[i + 1]?.ppr === null) {
                    items[i].spf = items[i + 1].spf;
                    items.splice(i + 1, 1);
                }
            }

            for (const x of items) {
                const prg = this.budgetTable.find((y) => y.prg === x.prg);
                if (x.spf === null
                    || (x.ppr === null && prg.pprChild === true)) {

                    makeToast(this, "danger", getSmsTitle(this, 'warning'),
                        this.getCdfTitle('fill_prg_ppr_spf'));
                    return;
                }
            }

            const data = [];
            for (const el of items) {
                for (const x of values.filter(
                    (v) => v.spf === el.spf && v.ppr === el.ppr && v.type === 6)) {
                    // eslint-disable-next-line standard/object-curly-even-spacing
                    data.push({ ...x, ppr: el.ppr });
                }
            }
            try {
                const response = await fetch("/api-py/save-cost-form/" + this.planPeriod.year,
                    {
                        method: "POST",
                        headers: {"Content-Type": "application/json;charset=utf-8"},
                        body: JSON.stringify(data)
                    }
                );
                const result = await response.json();

                if (response.status === 200 && result.result === "success") {
                    makeToast(this, "success", getSmsTitle(this, 'message'),
                        getSmsTitle(this, 'datas_saved'));
                    await this.rememberDatas();
                    await this.updateDatas();
                }
            } catch {
                makeToast(this, "danger", getSmsTitle(this, 'attention'),
                    getSmsTitle(this, 'error_save') + ' /api-py/save-cost-form/');
            }
        },

        toNum(value, fix = 1) {
            return getNumber(value, fix);
        },

        // итого по заданному полю
        total(field) {
            let sum = 0;

            for (const row of this.budgetTable) {
                if (row.type === 4) {
                    sum += getNumber(row[field]);
                }
            }
            return getNumber(sum);
        },

        // расставляет сохраненные данные по полям
        async updateDatas() {
            this.budgetTable.splice(0, this.budgetTable.length);
            try {
                this.pprMap.clear();
                await this.loadDictTree();

                if (this.selAbp) {
                    await this.prepareForm();
                    await this.rememberDatas();

                    for (const item of this.dictTree) {
                        if (item.abp === this.selAbp.abp) {
                            this.selAbp = item.value;
                            break;
                        }
                    }
                }
                this.changeFields();
                this.chgData();
            } finally {
                this.loading = false;
                this.budgetTable = this.budgetForm;
                if (this.firstVariant) {
                    makeToast(this, "warning", getSmsTitle(this, 'attention'),
                        this.getCdfTitle('first_forecast_ver_edit_limits_mode'));
                }
            }
        },

        updateIndex(data, item) {
            const that = this;
            this.$set(data, "edited", false);
            this.budgetForm.splice(data.index + 1, 0, item);
            this.calcFlds.forEach((field) => {
                this.$set(item, field, 0);
            });

            let ind = 0;
            for (const row of this.budgetForm) {
                this.$set(row, "index", ind++);
            }
            if (data.editable) {
                this.calcFlds.forEach((field) => {
                    Object.defineProperty(data, field, {
                        get: function () {
                            return that.reSum(data, field);
                        },
                    });
                });
            }
            this.$set(data, "editable", false);
            this.$set(data, "hasChild", true);
        },

        getCdfTitle(field) {
            return getCommonTitle(this, 'modules.costdataform.' + field)
        },

        getBipTitle(field) {
            return getBipTitle(this, field);
        },

        getCardTitle(sector, field) {
            return getCardTitle(this, sector, field);
        },

        getFilterTitle(field) {
            return getFilterTitle(this, field);
        },

        getTFieldTitle(field) {
            return getTFieldTitle(this, field);
        },

        getSmsTitle(field) {
            return getSmsTitle(this, field);
        }
    }
};
</script>
