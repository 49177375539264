























































import {Component, Prop, Vue, Emit} from 'vue-property-decorator';
import {BootstrapVueIcons} from 'bootstrap-vue';
import VueElementLoading from 'vue-element-loading';
import {parseInt} from "lodash";

Vue.use(BootstrapVueIcons);

interface IYearData {
    year: number;
    value?: number;
}

interface ICostRow {
    abp: number;
    prg: number;
    ppr: number;
    name_ru: string | undefined;
    visible: boolean;
    open: boolean;
    is_leaf: boolean;
    [x: string]: any
}

@Component({
    components: {
        'loading': VueElementLoading
    }
})

export default class BudgetPreviewCost extends Vue {
    private budgetCostData: ICostRow[] = [];
    private isBusy = false;
    private years_list: number[] = []

    public async openVariantData(variant: string) {
        try {
            this.isBusy = true;
            if (variant != '') {
                const response = await fetch('/api-py/get-coast-variant/' + variant);
                this.budgetCostData = await response.json();
                const side_fields = ['abp', 'prg', 'ppr', 'name_ru']
                for (const row of this.budgetCostData) {
                    const keys = Object.keys(row);
                    for (const k of keys) {
                        if (!side_fields.includes(k)) {
                            if (!this.years_list.includes(parseInt(k))) {
                                this.years_list.push(parseInt(k))
                            }
                        }
                    }
                }
                for (const row of this.budgetCostData) {
                    this.$set(row, 'visible', row.prg == null);
                    this.$set(row, 'open', row.prg != null);
                    this.$set(row, 'is_leaf', row.ppr != null);
                }
                this.$set(this.budgetCostData[0], 'open', true);
                this.$set(this.budgetCostData[0], 'is_leaf', false);
            }
        } catch (error) {
            console.debug(error)
            this.makeToast('danger', 'Ошибка загрузки данных. Нет данных по варианту', 'Сообщение');
        } finally {
            this.isBusy = false;
        }
    }

    makeToast(variant: string, tostbody: string, title: string) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            autoHideDelay: 4000,
            solid: true
        });
    }

    private openChild(row: any, index: number) {
        this.$set(row, 'open', !row.open);
        const vis_status = this.budgetCostData[index].open;
        for (let i = index + 1; i < this.budgetCostData.length; i++) {
            if (row.ppr == null) {
                if ((this.budgetCostData[i].abp == row.abp) && (this.budgetCostData[i].prg == row.prg)) {
                    this.$set(this.budgetCostData[i], 'visible', vis_status);
                }
            }
            if (row.prg == null) {
                if (this.budgetCostData[i].abp == row.abp) {
                    this.$set(this.budgetCostData[i], 'visible', vis_status);
                }
            }
            if (row.abp == null) {
                if (row.open) {
                    if (this.budgetCostData[i].prg == null) {
                        this.$set(this.budgetCostData[i], 'visible', true);
                        this.budgetCostData[i].open = false;
                    } else {
                        this.$set(this.budgetCostData[i], 'visible', false);
                    }
                } else {
                    this.$set(this.budgetCostData[i], 'visible', false);
                }
            }
        }
    }

    private rowClass(row: any) {
        if (row.abp == null) {
            return 'row-class-all';
        }
        if (row.prg == null) {
            return 'row-class-abp';
        }
        if (row.ppr == null) {
            return 'row-class-prg';
        }
    }
}


