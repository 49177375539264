







































import { Component, Vue, Prop } from 'vue-property-decorator';
import {getBipTitle, getTFieldTitle, itemNameLocale} from "./bip-types";

@Component({
    name: 'c-budg-agr-spf-modal'
})
export default class CBudgetAgreementSpfModal extends Vue {
    @Prop({
        required: true,
        default: {comment: '', match: false}
    })
    private spf: any;

    private showModal = false;
    private match = false;
    private notMatch = false;
    private comment = '';
    private delSpf = false;

    created() {
        this.$watch("showModal", (value) => {
            if (value) {
                itemNameLocale(this.spf);
                this.match = Boolean(this.spf.status.code);
                this.notMatch = !this.match;
                this.comment = this.spf.status.comment_txt;
                this.delSpf = false;
            }
        });
        this.$watch("match", (value) => {
            this.notMatch = !value;
        });
        this.$watch("notMatch", (value) => {
            this.match = !value;
        });
    }

    private showEvent() {
        this.showModal = true;
    }

    private saveSpf(delSpf: boolean = false) {
        const status = { code: this.match, comment_txt: this.comment, comment: this.comment, delete: delSpf };
        Object.assign(this.spf.status, status);
        this.$emit('saveSpf', this.spf);
        this.match = false;
        this.comment = '';
        this.showModal = false;
        this.delSpf = false;
    }

    private getBipTitle(field: string) {
        return getBipTitle(this, field);
    }

    private getTFieldTitle(field: string) {
        return getTFieldTitle(this, field);
    }
}
